// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import TemplatesList from './components/TemplateList';
import FileUpload from './components/FileUpload';

function App() {
  return (
    <Router>
      <Home></Home>
      <Footer></Footer>
    </Router>
   
  );
}

export default App;