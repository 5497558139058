import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "fuckYou",
    authDomain: "fuckYou",
    projectId: "F",
    storageBucket: "FuckYou",
    messagingSenderId: "F",
    appId: "F",
    measurementId: "F"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export {db, storage}